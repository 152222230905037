









































import { Component, mixins, Prop } from 'nuxt-property-decorator';
import ProjectTeaserMixin from '../../../mixins/ProjectTeaserMixin';
import BreakpointMixin from '../../../mixins/BreakpointMixin';
import MediaHandler from '@/components/utilities/media/MediaHandler';
import LinkManual from '@/components/utilities/links/LinkManual';

@Component({
  components: { MediaHandler, LinkManual }
})
export default class ProjectImageTeaser extends mixins(ProjectTeaserMixin, BreakpointMixin) {
  @Prop({ required: false, default: false }) in_slider?: boolean;
  is_hovered: boolean = false;
}
