import { Component, Prop } from 'nuxt-property-decorator';
import Vue from 'vue';
import { ContentPageProject, StoryblokAsset, StoryblokStory } from '../types';
import { getProjects } from '../store';

@Component
class ProjectTeaserMixin extends Vue {
  @Prop() uuid!: string;
  get entry (): StoryblokStory|false {
    return getProjects(this.$store).entryByUuid(this.uuid);
  }
  get project (): ContentPageProject|false {
    if (!this.entry) { return false; }
    return this.entry.content;
  }
  get media (): StoryblokAsset|false {
    if (!this.project) { return false; }
    if (this.project.thumbnail_teaser && this.project.thumbnail_teaser.filename) {
      return this.project.thumbnail_teaser;
    }
    if (this.project.thumbnail && this.project.thumbnail.filename) {
      return this.project.thumbnail;
    }
    return false;
  }
  get title (): string|false {
    if (!this.project) { return false; }
    const titlebox = this.project.title;
    if (!titlebox.length) { return false; }
    return titlebox[0].title.replace('<br>', ' ');
  }
  get tags (): string|false {
    if (!this.project) { return false; }
    if (!this.project.tags.length) { return false; }
    let tags = '';
    this.project.tags.forEach((tag) => {
      tags += `${tag}<br>`;
    });
    return tags;
  }
  get color (): string {
    if (!this.project || !this.project.color_teaser || !this.project.color_teaser.color) {
      return '#000000';
    } else {
      return this.project.color_teaser.color;
    }
  }
}
export default ProjectTeaserMixin;
