import { render, staticRenderFns } from "./ProjectsSlider.vue?vue&type=template&id=2f3d840c&scoped=true&"
import script from "./ProjectsSlider.vue?vue&type=script&lang=ts&"
export * from "./ProjectsSlider.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectsSlider.vue?vue&type=style&index=0&id=2f3d840c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f3d840c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProjectImageTeaser: require('/opt/build/repo/components/items/teasers/ProjectImageTeaser.vue').default})
